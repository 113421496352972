/*!
 * FullCalendar v1.6.4 Print Stylesheet
 * Docs & License: http://arshaw.com/fullcalendar/
 * (c) 2013 Adam Shaw
 */

/*
 * Include this stylesheet on your page to get a more printer-friendly calendar.
 * When including this stylesheet, use the media='print' attribute of the <link> tag.
 * Make sure to include this stylesheet IN ADDITION to the regular fullcalendar.css.
 */

/* Events
-----------------------------------------------------*/

.fc-event {
  background: #fff;
  color: #000;
}

/* for vertical events */

.fc-event-bg {
  display: none;
}

.fc-event .ui-resizable-handle {
  display: none;
}
